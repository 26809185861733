import { NhostClient } from '@nhost/vue';
import { subdomain, region } from "../env.js";

//autenticacao nhost
const nhostAuth = new NhostClient({
  subdomain,
  region,
});

export {nhostAuth}
